@import '../theme-bootstrap';

// ratings and reviews container optimal width
// below wide breaks into two lines
$ratings-container-wide: min-width 1450px;

.mpp-container {
  &__header {
    @include h1;
    max-width: $mpp-max-width;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
    @include breakpoint($large-up) {
      margin: $spacing auto 0;
    }
  }
  @include breakpoint($large-up) {
    padding-bottom: 60px;
  }
}

.product-brief {
  &__container {
    padding: 0 5px 20px;
    @include breakpoint($landscape-up) {
      padding: 0 12px 45px;
    }
    .product-grid--carousel & {
      padding-bottom: 0;
    }
  }
  &__content {
    @include swap_direction(margin, 3% 0 0 3%);
    @include breakpoint($landscape-up) {
      margin: 0;
    }
  }
  &__sale {
    color: $color-red;
    text-transform: uppercase;
  }
  &__name {
    @include h3;
    a {
      text-decoration: none;
    }
    @include breakpoint($landscape-up) {
      padding: 8px 0 8px;
    }
  }
  &__subline {
    text-transform: uppercase;
  }
  &__price {
    padding-bottom: 8px;
  }
  &__quickshop-launch,
  &__cta {
    text-decoration: underline;
    text-transform: uppercase;
    &--shop-now {
      display: block;
      margin-top: 1%;
    }
  }
  &__sku-shade {
    display: block;
    margin-top: 1%;
    @include breakpoint($landscape-up) {
      color: $color-black;
      margin-top: 0;
    }
  }
  &__image {
    background: $color-bg;
    min-height: 160px;
    position: relative;
    width: 100%;
  }
  .product__image--med {
    width: 100%;
    height: auto;
  }
  .product__image--med-badge {
    width: 100%;
    height: auto;
    position: absolute;
  }
}

.zoomContainer {
  display: none;
  .pointerevents & {
    @include breakpoint($landscape-up) {
      display: block;
    }
  }
}

.product-full {
  padding: 0.5em 0 0;
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  @include breakpoint($landscape-up) {
    @include pie-clearfix;
    @include swap_direction(padding, 28px 40px 0 41px);
    .product-breadcrumb {
      line-height: 71px;
    }
  }
  &__review-snippet > .p-w-r ~ .p-w-r {
    .pr-review-snippet-container {
      display: none;
    }
  }
  &__breadcrumb {
    display: block;
    min-height: 2em;
  }
  &__name {
    @include font--subheading-alt;
    font-size: 31px;
    line-height: 0.9;
    margin: 0;
  }
  &__subline {
    @include font--subheading;
    color: $color-text-grey;
    font-size: 17px;
    margin: 0;
    a {
      color: $color-text-grey;
    }
    &.sub_line {
      color: $color-black;
      a {
        color: $color-black;
      }
    }
  }
  &__sub-row {
    @include pie-clearfix;
    position: relative;
    clear: both;
    width: 100%;
    padding-top: 30px;
    @include breakpoint($portrait-up) {
      height: 40px;
      padding-top: 0;
    }
    @include breakpoint($landscape-up) {
      margin-top: -5px;
    }
  }
  &__price {
    @include font--subheading-alt;
    float: $ldirection;
    font-size: 22px;
    padding-top: 18px;
  }
  &__size--shaded {
    display: inline-block;
    float: $ldirection;
    @include swap_direction(padding, 21px 0 0 20px);
    vertical-align: bottom;
    @include breakpoint($landscape-up) {
      position: relative;
      max-width: 50%;
    }
    @include breakpoint($ratings-container-wide) {
      max-width: inherit;
    }
  }
  &__unit-price {
    display: inline-block;
    float: $ldirection;
    vertical-align: bottom;
    padding-top: 23px;
  }
  &__title-container {
    position: relative;
    @include breakpoint($landscape-up) {
      min-height: 75px;
    }
  }
  &__rating {
    position: absolute;
    #{$ldirection}: 0;
    z-index: 1;
    @include breakpoint($portrait-up) {
      top: auto;
      #{$ldirection}: auto;
      bottom: -45px;
      #{$rdirection}: -18px;
    }
    @include breakpoint($landscape-up) {
      position: absolute;
      #{$rdirection}: 5%;
      bottom: -42px;
      width: 110px;
    }
    @include breakpoint($ratings-container-wide) {
      width: inherit;
      bottom: -8px;
      #{$rdirection}: -125px;
    }
    .product-rating__stars-wrapper {
      float: $ldirection;
      margin-#{$rdirection}: 12px;
    }
    .product-rating__count {
      padding-top: 13px;
      display: inline-block;
      float: $rdirection;
    }
  }
  &__details {
    @include breakpoint($landscape-up) {
      float: $rdirection;
      width: 45%;
      max-width: 533px;
      margin-top: -5px;
    }
  }
  &__image-wrapper {
    min-height: 3em;
    width: 100%;
    margin-bottom: 1em;
    @include breakpoint($landscape-up) {
      margin-bottom: 0;
      float: $ldirection;
      width: 55%;
    }
  }
  &__image {
    position: relative;
    @include breakpoint($landscape-up) {
      margin-#{$rdirection}: 2em;
    }
  }
  &__image-badge {
    position: absolute;
    // @todo make this carousel-aware when we finalize sku swapping of images
    z-index: 2;
  }
  &__carousel__thumbnails {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
    }
  }
  &__carousel-dots {
    margin: 1em 0 2em;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &__carousel__slide {
    position: relative;
    display: none;
    // Prevent FOUC
    .slick-initialized & {
      display: block;
    }
  }
  &__carousel__thumbnail {
    display: none;
    width: 86px;
    float: $ldirection;
    @include swap_direction(margin, 8px 8px 0 0);
    opacity: 0.15;
    padding: 1px;
    @include breakpoint($landscape-up) {
      display: inline-block;
    }
    &.slick-active {
      opacity: 1;
    }
  }
  &__product-social {
    padding: 8px 0 0;
    float: $rdirection;
    position: relative;
    z-index: 1;
    .product-full__image-wrapper--has-carousel & {
      @include breakpoint($landscape-up) {
        margin-top: -96px;
        position: absolute;
        #{$rdirection}: 0;
      }
    }
    a:hover {
      text-decoration: none;
    }
  }
  .product__button--add-to-uag {
    display: block;
    text-align: center;
    @include breakpoint($landscape-up) {
      display: inline-block;
      margin-top: 20px;
    }
    @include breakpoint($ratings-container-wide) {
      margin-top: 5px;
    }
  }
  .product__button--loading {
    @include breakpoint($landscape-up) {
      margin-top: 20px;
    }
    @include breakpoint($ratings-container-wide) {
      margin-top: 5px;
    }
  }
  .product__button--success {
    @include breakpoint($landscape-up) {
      margin-top: 20px;
    }
    @include breakpoint($ratings-container-wide) {
      margin-top: 5px;
    }
  }
  .product__button--add-favorites {
    @include breakpoint($landscape-up) {
      font-size: 17px;
    }
  }
  .product-full__button-row {
    margin-bottom: 1em;
  }
  .egift-cta {
    float: $ldirection;
    display: block;
    padding-top: 6px;
    &__button {
      background-color: $color-white;
      border-width: 0;
      cursor: pointer;
      color: $color-black;
      display: inline;
      margin-bottom: 13px;
      padding-#{$ldirection}: 0px;
      padding-#{$rdirection}: 0px;
      text-decoration: underline;
    }
    a {
      text-decoration: none;
      font-size: 17px;
      margin-top: -4px;
      &.egift-cta__info {
        display: inline-block;
      }
    }
    .icon {
      vertical-align: text-top;
    }
  }
  &__estimated-delivery {
    clear: both;
    margin: 0.5em;
    @include breakpoint($landscape-up) {
      margin: 1em 0 25px;
    }
  }
  &__ingredients-label {
    @include font--heading;
    cursor: pointer;
  }
  // single shade display
  &__shade-single {
    clear: both;
    line-height: 30px;
  }
  &__shade-single-label {
    font-weight: bold;
  }
  &__shade-single-swatch {
    display: block;
    float: $ldirection;
    margin-#{$rdirection}: 5px;
    width: 30px;
    height: 30px;
  }
}

// Zoom Window jQuery plugin
.zoom-show {
  .zoomContainer {
    cursor: zoom-out;
    cursor: -webkit-zoom-out;
  }
  .zoomWindowContainer {
    visibility: visible;
    display: block;
  }
  // hide details when zoom window active
  &.zoom-hide-background .product-full__details {
    opacity: 0;
  }
  // otherwise show it
  .product-full__details {
    opacity: 1;
  }
}

.zoomWindowContainer {
  visibility: hidden;
  display: none;
}

.zoomContainer {
  cursor: zoom-in;
  cursor: -webkit-zoom-in;
}

// Afterpay installment messsage styles in SPP
.afterpay-paragraph {
    margin-top: 20px;
    .afterpay-link {
      text-transform: lowercase;
    }
  }